import React from "react";
import { Form, Input } from "antd";
import { DateableDetail } from "../DateableDetail/index.js";
import { isStartDateDisabled } from "../../../utils/functions.js";


export const ReportTemplateFields = ({
    startDate,
    setStartDate,
    latestVersionStartDate,
    version,
    setVersion,
    reportTemplateVersionsList,
    effictiveDateEditable = true,
    form,
    editReportTemplate = false,
    selectedVersion
}) => {

    const validateVersion = (_, inputValue) => {
        if (/^(\d+(\.\d+)*)?$/.test(inputValue)) {
            return Promise.resolve();
        } else {
            return Promise.reject("Invalid format. Only digits & periods are allowed.");
        }
    };

    const validateDuplicateVersion = (_, value) => {
        if (!value) {
            return Promise.resolve();
        }

        if (reportTemplateVersionsList?.includes(value.trim())) {
            if (editReportTemplate && value.trim() == selectedVersion) {
                return Promise.resolve();
            } else 
                return Promise.reject("Version already exists.");
        }
        return Promise.resolve();
    };

    const handleVersionChange = (e) => {
        const inputValue = e.target.value;
        setVersion(inputValue);
    };


    return (
        <div>
            <div style={{ display: "flex", columnGap: "10px", marginTop: "4px" }}>
                <div style={{ fontWeight: 600, marginTop: "6px" }}>Report Template Version: </div>
                <Form form={form}
                    initialValues={{ version: version }}
                    clearOnDestroy
                >
                    <Form.Item
                        name="version"
                        rules={[{ validator: validateVersion }, { validator: validateDuplicateVersion }, { required: true, message: "Required" }]}
                        validateFirst
                    >
                        <Input
                            value={version}
                            onChange={handleVersionChange}
                        />
                    </Form.Item>
                </Form>
            </div>
            <div style={{ display: "flex", columnGap: "10px" }}>
                <div style={{ fontWeight: 600, marginTop: "4px" }}>Effective Date:</div>
                <div>
                    <DateableDetail
                        form={form}
                        readOnly={!effictiveDateEditable}
                        key="startDate"
                        value={startDate}
                        disabledDate={(date) => isStartDateDisabled(date, latestVersionStartDate)}
                        onValueUpdated={(value) => {
                            setStartDate(value);
                        }}
                        isReportTemplate
                    />
                </div>
            </div>
        </div>
    );
};