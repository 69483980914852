import dayjs from "dayjs";
import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { Modal, Radio, Form } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { Table } from "../common/Table/index.js";
import { S3UploadStatus } from "../common/S3UploadStatus/index.js";
import { UploadLibraryDocument } from "../common/UploadLibraryDocument/index.js";
import { MODIFY_LIBRARY_DOCUMENT } from "../../constants/mutations.js";
import { useMutation } from "@apollo/client";
import { LibraryDocumentActions } from "../common/LibraryDocumentActions/index.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { ReportTemplateFields } from "../common/DocumentUploadModal/reportTemplateFields.js";

export const ReportTemplatesTable = ({
    loading,
    reportTemplate,
    currentFolderId,
    baseFolderName,
    contractTypeId,
    archive,
    refetch
}) => {
    const { confirm } = Modal;
    const [form] = Form.useForm();
    const [activeToggle, setActiveToggle] = useState(true);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const [startDate, setStartDate] = useState();
    const [version, setVersion] = useState();
    const [submittable, setSubmittable] = useState(false);
    const {
        userPermUploadnModifyLibDoc,
        userPermEditLibraryDocuments
    } = useContext(UserContext);
    const [modifyLibraryDocument] = useMutation(MODIFY_LIBRARY_DOCUMENT);
    const values = Form.useWatch([], form);

    useEffect(() => {
        // Check if any field is touched
        const isTouched = form.isFieldTouched("version") || form.isFieldTouched("startDate");
        if (isTouched) {
            form
                .validateFields({ validateOnly: true })
                .then(() => setSubmittable(true))
                .catch(() => setSubmittable(false));
        } else {
            setSubmittable(false);
        }
    }, [form, values]);

    const reportTemplateList = reportTemplate.versions.filter(({ reportTemplateStatus }) => {
        return activeToggle
            ? reportTemplateStatus !== "Inactive"
            : reportTemplateStatus === "Inactive";
    }).filter((item)=>!item.deleted);

    const canUploadDocuments = userPermUploadnModifyLibDoc && archive;
    const reportTemplateVersionsList = reportTemplate.versions.map(({ versionNumber }) => versionNumber );
    const openEditModal = () => setOpen(true);
    const closeEditModal = () => {
        setOpen(false);
        setStartDate("");
    };

    const handleDeletion = (record) => {
        confirm({
            title:
                "Are you sure you want to delete this report template document?",
            content: (
                <>
                    <div><b>Name: </b>{record?.name}</div>
                    <div><b>Version: </b>{record?.versionNumber}</div>
                </>
            ),
            okText: "Yes",
            onOk: async () => {
                await handleMutation(
                    modifyLibraryDocument({
                        variables: {
                            id: record?.libraryDocumentId,
                            deleted: !record?.deleted
                        }
                    }),{
                        showSuccess: true,
                        successMessage: `Report template document ${record?.name} successfully deleted!`
                    } );
                refetch();
            }
        });
    };

    const rightAlignedRadioButtons =
        <Radio.Group
            defaultValue={activeToggle}
            value={activeToggle}
            onChange={(e) => {
                setActiveToggle(e.target.value);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={true} >Active </Radio.Button>
            <Radio.Button value={false}>Inactive </Radio.Button>
        </Radio.Group>;


    const reportTemplateTable = (
        <Table
            loading={loading}
            key="reportTemplateTable"
            size="middle"
            pagination={false}
            rowKey="libraryDocumentId"
            columns={[
                {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    width: "20%"
                },
                {
                    key: "startDate",
                    title: "Effective Date",
                    dataIndex: "startDate",
                    width: "15%",
                    render: (startDate, { endDate }) => {
                        return <>{dayjs(startDate).format("MM/DD/YYYY")} {endDate && <span> - {dayjs(endDate).format("MM/DD/YYYY")}</span>}</>;
                    }
                },
                {
                    key: "versionNumber",
                    title: "Version",
                    dataIndex: "versionNumber",
                    width: "10%"
                },
                {
                    key: "dateCreated",
                    title: "Uploaded Date",
                    dataIndex: "dateCreated",
                    width: "10%",
                    render: (dateCreated) => {
                        return <>{moment(parseInt(dateCreated)).format("MM/DD/YYYY")}</>;
                    }
                },
                {
                    key: "S3UploadStatus",
                    title: "Upload Status",
                    dataIndex: "S3UploadStatus",
                    width: "10%",
                    render: S3UploadStatus
                },
                {
                    title: "Template Status",
                    dataIndex: "reportTemplateStatus",
                    key: "reportTemplateStatus",
                    width: "10%"
                },
                {
                    title: "Actions",
                    key: "actions",
                    width: "10%",
                    render: (document) => {
                        const userCanUpload = userPermUploadnModifyLibDoc;
                        const userCanDelete = userPermEditLibraryDocuments && archive && document?.deletable && document.reportTemplateStatus !== "Inactive";
                        const userCanEdit = userPermEditLibraryDocuments && archive && document.reportTemplateStatus !== "Inactive";
                        const handleEdit = () => {
                            setSelected(document);
                            setStartDate(document?.startDate);
                            setVersion(document?.versionNumber);
                            openEditModal();
                        };
                        return <LibraryDocumentActions
                            editDocument={handleEdit}
                            handleDeletion={handleDeletion}
                            record={{
                                id: document.libraryDocumentId,
                                ...document
                            }}
                            userCanDelete={userCanDelete}
                            userCanEdit={userCanEdit}
                            userCanUpload={userCanUpload}
                            libraryFolderId={currentFolderId}
                            reportUpdatedDocument={() => refetch()}
                            onReuploadFailure={refetch}
                            buttonStyle={{ marginLeft: "8px" }}
                        />;
                    }
                }]}
            dataSource={reportTemplateList}
        />
    );

    const editReportTemplate = () => {
        let effictiveDateEditable;

        if (reportTemplate?.latestVersion.specifier === selected?.specifier ) {
            effictiveDateEditable = true;
        } else {
            effictiveDateEditable = false;
        }
        let latestVersionStartDate;
        const lastestVersionNumber = reportTemplate?.latestVersion?.specifier;

        if (lastestVersionNumber === 0) {
            const currentDate = moment().startOf("day");
            const latestVersionDate = moment(reportTemplate?.latestVersion?.startDate, "YYYY/MM/DD").startOf("day");
            latestVersionStartDate = currentDate > latestVersionDate ? latestVersionDate : currentDate;

        } else {
            const previousVersionStartDateVersion = reportTemplate?.versions.find(({ specifier }) => specifier === (lastestVersionNumber - 1));
            latestVersionStartDate = moment(previousVersionStartDateVersion.startDate, "YYYY/MM/DD");
        }

        return (
            <Modal
                title={"Edit Report Template Details"}
                open={open}
                style={{ minWidth: 100 }}
                okText={"Save"}
                okButtonProps={{ disabled: !submittable}}                
                cancelText={"Cancel"}
                onOk={async () => {
                    let variables;
                    if (reportTemplate?.latestVersion.specifier === selected?.specifier) {
                        variables = {
                            id: selected?.libraryDocumentId,
                            startDate: startDate,
                            version
                        };
                    } else {
                        variables = {
                            id: selected?.libraryDocumentId,
                            version
                        };
                    }
                    await handleMutation(
                        modifyLibraryDocument({
                            variables: variables
                        })
                    );
                    refetch();
                    closeEditModal();
                }}
                onCancel={closeEditModal}
                destroyOnClose={true}
            >
                <div>
                    <div><b>Name:</b> {selected?.name}</div>                    
                    <div>                        
                        <ReportTemplateFields
                            startDate = {startDate}
                            setStartDate={setStartDate}
                            latestVersionStartDate={latestVersionStartDate}
                            version={version}
                            setVersion={setVersion}
                            reportTemplateVersionsList={reportTemplateVersionsList}
                            form={form}
                            effictiveDateEditable={effictiveDateEditable}
                            selectedVersion={selected?.versionNumber}
                            editReportTemplate={true}
                        >
                        </ReportTemplateFields>
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <>
            <div className="main-content-margin-top">
                <div style={{
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    float: "inline-end"
                }}>

                    <div style={{
                        float: "left"
                    }}>
                        <div style={{ display: "flex" }}>
                            <div><span style={{
                                fontSize: "16px",
                                fontWeight: "600"
                            }}>Report Templates</span></div>
                            <div style={{ marginLeft: "16px" }}>
                                {canUploadDocuments
                                    ? <UploadLibraryDocument
                                        afterUpload={() => {
                                            refetch();
                                        }}
                                        reportTemplateId={reportTemplate?.id}
                                        currentFolderId={currentFolderId}
                                        baseFolderName={baseFolderName}
                                        contractTypeId={contractTypeId}
                                        isReportTemplate={true}
                                        reportTemplateVersion={true}
                                        latestVersionStartDate={reportTemplate.latestVersion?.startDate}
                                        reportTemplateVersionsList={reportTemplateVersionsList}
                                    />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{
                        float: "right"
                    }}>
                        {rightAlignedRadioButtons}
                    </div>
                </div>
                {reportTemplateTable}
            </div>

            {editReportTemplate()}
        </>
    );
};